import React from 'react'

import Section from '../../components/Section'
import { GridContainer, ContentContainer } from '../../components/Grid'
import { Paragraph } from '../../components/Typography'
import { Colors } from '../../utils/styles'

const coreMissionText = `
  At Pulse Analytics our core mission is to help decision-makers in oncology and other specialty therapeutic areas, identify and reduce access barriers across the healthcare industry, ensuring patients have access to the treatments they need.
`

const CoreMission = () => (
  <Section
    backgroundColor={Colors.WHITE}
  >
    <GridContainer style={{ textAlign: 'center' }} justifyContent="center">
      <ContentContainer>
        <Paragraph large style={{ fontWeight: 700, color: Colors.PRIMARY, padding: '48px 0' }}>
          { coreMissionText }
        </Paragraph>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default CoreMission
