/* global fetch, whr_embed */

import React, { useState, useEffect } from 'react'

import Section from '../../components/Section'
import { GridContainer, ContentContainer } from '../../components/Grid'
import { H2, Paragraph } from '../../components/Typography'
import { Colors } from '../../utils/styles'

// import PositionCard from './PositionCard'

const herobackgroundImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1542296852/pulse-analytics-marketing/backgrounds/background-flat-bubbles-1-2_2x.png' // eslint-disable-line

const sectionDescription = 'Pulse Analytics is currently expanding our team. We are looking for talented and ambitious individuals to bring their expertise to our product.' // eslint-disable-line

const OpenPositions = () => {
  // const [posts, setPosts] = useState([])
  useEffect(() => {
    whr_embed(556364, {
      detail: 'titles', base: 'jobs', zoom: 'country', grouping: 'departments'
    })
  }, [])

  return (
    <Section id="careers--open-positions" backgroundImage={herobackgroundImage}>
      <GridContainer flexWrap="wrap">
        <ContentContainer width={[1, 1, 1 / 2]}>
          <H2 style={{ color: Colors.PRIMARY }}>Join Our Team</H2>
          <Paragraph large>
            { sectionDescription }
          </Paragraph>
        </ContentContainer>
        <ContentContainer width={[1, 1, 1 / 2]}>
          {/* {posts.length > 0 && posts.map(({ absolute_url: absoluteUrl, title, id }) => (
            <PositionCard
              key={id}
              width={[1]}
              title={title}
              id={id}
              link={absoluteUrl}
            />
          ))} */}
          {/* <div id="whr_embed_hook" /> */}
        </ContentContainer>
      </GridContainer>
    </Section>
  )
}

export default OpenPositions
