import React from 'react'
import styled from '@emotion/styled'

import Section from '../../components/Section'
import { GridContainer } from '../../components/Grid'
import { H2, Paragraph } from '../../components/Typography'
import { Colors } from '../../utils/styles'
import BenefitTile from './BenefitTile'

const compensationIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-compensation-1-blue.svg' // eslint-disable-line
const healthcareIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-healthcare-1-blue.svg' // eslint-disable-line
const vacationIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-vacation-1-blue.svg' // eslint-disable-line
const remoteIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-remote-1-blue.svg' // eslint-disable-line
const educationIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-education-1-blue.svg' // eslint-disable-line
const dinnerIcon = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147527/pulse-analytics-marketing/icons/company-benefits/perk-dinner-1-blue.svg' // eslint-disable-line

const sectionTitle = 'Benefits at Pulse Analytics'
const sectionDescription = 'Besides working on interesting and challenging data-driven products there are other benefits that make working at Pulse Analytics great:' // eslint-disable-line

const DescriptionContainer = styled.div({
  textAlign: 'center',
  width: '100%',
  marginBottom: 48,
})

const BenefitTilesContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
})

const Benefits = () => (
  <Section backgroundColor={Colors.WHITE}>
    <GridContainer flexDirection="column">
      <DescriptionContainer>
        <H2>{ sectionTitle }</H2>
        <Paragraph large>{ sectionDescription }</Paragraph>
      </DescriptionContainer>
      <BenefitTilesContainer>
        <BenefitTile title="Competitive compensation + 401K" image={compensationIcon} />
        <BenefitTile title="Full healthcare coverage" image={healthcareIcon} />
        <BenefitTile title="Flexible vacation policy" image={vacationIcon} />
        <BenefitTile title="Remote work flexibility" image={remoteIcon} />
        <BenefitTile title="Education and career development" image={educationIcon} />
        <BenefitTile title="Quarterly team dinners + weekly happy hours" image={dinnerIcon} />
      </BenefitTilesContainer>
    </GridContainer>
  </Section>
)

export default Benefits
