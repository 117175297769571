import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mix } from 'polished'

import { AlphaColors, Colors, mediaQueryUtility } from '../../utils/styles'

const Section = styled.div(props => ({
  background: 'transparent',
  backgroundRepeat: 'no-repeat',
  MozBackgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  WebkitBackgroundSize: 'cover',
  MozBackgroundSize: 'cover',
  OBackgroundSize: 'cover',
  backgroundSize: 'cover',
  MsBackgroundSize: 'cover',
  position: 'relative',
  width: '100%',
  height: 'auto',
  [mediaQueryUtility[0]]: {
    background: `url(${props.poster}), ${Colors.PRIMARY}`,
  },
  '@supports (-ms-ime-align:auto)': {
    background: `url(${props.poster}), ${Colors.PRIMARY}`,
  },
}))

const VideoBackground = styled.video({
  background: mix(0.5, Colors.PRIMARY, Colors.BLACK),
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: '-1',
  [mediaQueryUtility[0]]: {
    display: 'none',
  },
  '@supports (-ms-ime-align:auto)': {
    display: 'none',
  },
})

const OverlayBackground = styled.div(props => ({
  // Use this background to add overlay FX to video
  background: props.videoOverlay,
  zIndex: 10,
  position: 'relative',
  height: '100%',
  padding: props.paddingDesktop,
  [mediaQueryUtility[1]]: {
    padding: props.paddingTablet,
  },
  [mediaQueryUtility[0]]: {
    padding: props.paddingMobile,
  },
}))

const VideoHero = props => (
  <Section poster={props.poster}>
    <VideoBackground
      playsinline
      autoPlay
      muted
      loop
      width="100%"
      height="100%"
      poster={props.poster}
    >
      <source src={props.video} type="video/mp4" />
    </VideoBackground>
    <OverlayBackground
      videoOverlay={props.videoOverlay}
      paddingDesktop={props.paddingDesktop}
      paddingTablet={props.paddingTablet}
      paddingMobile={props.paddingMobile}
    >
      {props.children}
    </OverlayBackground>
  </Section>
)

export default VideoHero

VideoHero.propTypes = {
  poster: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  videoOverlay: PropTypes.string,
  paddingDesktop: PropTypes.string,
  paddingTablet: PropTypes.string,
  paddingMobile: PropTypes.string,
}

VideoHero.defaultProps = {
  videoOverlay: AlphaColors.BLACK_10,
  paddingDesktop: '80px 0',
  paddingTablet: '60px 0',
  paddingMobile: '60px 0',
}
