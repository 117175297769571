import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

import { H4, Paragraph } from '../../components/Typography'
import Image from '../../components/Image'
import { Colors } from '../../utils/styles'

const StyledBox = styled(Box)({
  width: '100%',
  padding: 16,
})

const Container = styled.div({
  background: Colors.WHITE,
  borderRadius: 8,
  width: '100%',
  padding: 32,
  height: '100%',
  textAlign: 'center',
})

const imageSize = '60px'

const ValueCard = props => (
  <StyledBox width={[1, 1 / 2, 1 / 3]}>
    <Container>
      <Image height={imageSize} width={imageSize} src={props.image} />
      <H4 style={{ color: Colors.PRIMARY, marginTop: 16 }}>{props.title}</H4>
      <Paragraph>{props.description}</Paragraph>
    </Container>
  </StyledBox>
)

export default ValueCard


ValueCard.defaultProps = {
  title: 'Value Title',
  description: 'Values and principle descriptions',
  image: 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-service-1.svg', // eslint-disable-line
}

ValueCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}
