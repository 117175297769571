import React from 'react'

import SEO from '../components/seo'
import LayoutCareers from '../pageLayouts/LayoutCareers'

const CareersPage = () => (
  <div>
    <SEO title="Careers" />
    <LayoutCareers />
  </div>
)

export default CareersPage
