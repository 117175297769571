import React from 'react'
import { Flex } from '@rebass/grid/emotion'

import Section from '../../components/Section'
import { GridContainer, ContentContainer } from '../../components/Grid'
import { H2 } from '../../components/Typography'

import ValueCard from './ValueCard'

const serviceTitle = 'Service'
const serviceDescription = 'We adopt a client-first approach to our tools and solutions. We strive to bring our clients high-quality products and services to meet their individual needs. This builds trust in the relationship and differentiates us as market leaders in the space.' // eslint-disable-line
const serviceImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-service-1.svg' // eslint-disable-line

const agileTitle = 'Agile'
const agileDescription = 'Being flexible in our approach allows us to adapt and iterate quickly to service demands or needs. We believe that incremental delivery through small iterative cycles is the most efficient way to get value into the hands of our users.' // eslint-disable-line
const agileImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-adaptability-1.svg' // eslint-disable-line

const transparencyTitle = 'Transparency'
const transparencyDescription = 'Having everyone on the same page helps us make better-informed decisions as a team. We encourage discussion and feedback at all levels through our company-wide planning and retrospectives.' // eslint-disable-line
const transparencyImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-transparency-1.svg' // eslint-disable-line

const innovationTitle = 'Innovation'
const innovationDescription = 'We strive for excellence, embrace risk-taking, and take bold actions to innovate and improve. We are never content with the status quo and are always looking to improve our product and processes.' // eslint-disable-line
const innovationImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-innovation-1.svg' // eslint-disable-line

const ownershipTitle = 'Ownership'
const ownershipDescription = 'We act and think like owners and partners, hold each other accountable, operate with integrity, and treat each other with respect.' // eslint-disable-line
const ownershipImage = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1606147538/pulse-analytics-marketing/icons/company-values/value-selfaccountability-1.svg' // eslint-disable-line

const PrinciplesValues = () => (
  <Section>
    <GridContainer justifyContent="center" style={{ textAlign: 'center' }}>
      <ContentContainer>
        <H2>Our Principles and Values</H2>
        <Flex flexWrap="wrap" justifyContent="center">
          <ValueCard
            title={serviceTitle}
            description={serviceDescription}
            image={serviceImage}
          />
          <ValueCard
            title={agileTitle}
            description={agileDescription}
            image={agileImage}
          />
          <ValueCard
            title={transparencyTitle}
            description={transparencyDescription}
            image={transparencyImage}
          />
          <ValueCard
            title={innovationTitle}
            description={innovationDescription}
            image={innovationImage}
          />
          <ValueCard
            title={ownershipTitle}
            description={ownershipDescription}
            image={ownershipImage}
          />
        </Flex>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default PrinciplesValues
