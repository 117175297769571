import React from 'react'
import { transparentize } from 'polished'

import Section from '../../components/Section'
import { GridContainer, ContentContainer } from '../../components/Grid'
import { H1, Paragraph } from '../../components/Typography'
import ButtonLink from '../../components/Buttons'
import { Colors } from '../../utils/styles'

const description = 'Learn more about our journey and the technical and design decisions we’ve made to drive development of our decision-support tools' // eslint-disable-line
const sectionBackground = 'https://res.cloudinary.com/pulsedatatools/image/upload/v1543855683/pulse-analytics-marketing/backgrounds/background-banner-code-1_2x.png' // eslint-disable-line

const BlogCta = () => (
  <Section
    backgroundImage={sectionBackground}
    backgroundColor={transparentize(0.5, Colors.BLACK)}
  >
    <GridContainer>
      <ContentContainer width={[1, 1, 1 / 2]}>
        <H1 style={{ color: Colors.WHITE }}>PULSE Blog</H1>
        <Paragraph large style={{ fontWeight: 700, color: Colors.WHITE, marginBottom: 48 }}>
          { description }
        </Paragraph>
        <ButtonLink
          buttonColor={Colors.WHITE}
          textColor={Colors.PRIMARY}
          to="/blog"
        >
          View Blog Posts
        </ButtonLink>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default BlogCta
