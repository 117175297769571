import React from 'react'

import Layout from '../../components/Layout'

import CareersHero from './CareersHero'
import CoreMission from './CoreMission'
import PrinciplesValues from './PrinciplesValues'
import Benefits from './Benefits'
import OpenPositions from './OpenPositions'
import BlogCta from './BlogCta'

const LayoutCareers = () => (
  <Layout>
    <CareersHero />
    <CoreMission />
    <PrinciplesValues />
    <Benefits />
    <OpenPositions />
    <BlogCta />
  </Layout>
)

export default LayoutCareers
