import React from 'react'

import { H1, H4 } from '../../components/Typography'
import { GridContainer, ContentContainer } from '../../components/Grid'
import ButtonLink from '../../components/Buttons'
import VideoHero from '../../components/VideoHero'

import { AlphaColors, Colors } from '../../utils/styles'

const CareersHero = () => (
  <VideoHero
    poster="https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_85,w_1440/v1550865941/pulse-digital/backgrounds/video-backgrounds/pulse-digital-careers-hero-background-2_poster.png" // eslint-disable-line
    video="https://res.cloudinary.com/pulsedatatools/video/upload/q_80/v1550865739/pulse-digital/backgrounds/video-backgrounds/pulse-digital-careers-hero-background-1_compressed.mp4" // eslint-disable-line
    videoOverlay={AlphaColors.BLACK_40}
    paddingDesktop="120px 0"
  >
    <GridContainer>
      <ContentContainer>
        <H4 style={{ color: Colors.WHITE }}>Careers at PULSE</H4>
        <H1 style={{ color: Colors.WHITE, marginBottom: 48 }}>Come work on data-driven products</H1>
        <div>
          <ButtonLink
            to="/careers/#careers--open-positions"
            buttonColor={Colors.WHITE}
            textColor={Colors.PRIMARY}
            style={{ marginRight: 24, marginBottom: 24 }}
          >
            Jump to Open Positions
          </ButtonLink>
          <ButtonLink
            to="/blog"
            secondary
            textColor={Colors.WHITE}
            buttonColor={Colors.WHITE}
          >
            Read our Tech Blog
          </ButtonLink>
        </div>
      </ContentContainer>
    </GridContainer>
  </VideoHero>
)

export default CareersHero
